import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import CambioModel from 'src/model/veiculo/CambioModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import IcarrosCambioModel from 'src/model/veiculo/IcarrosCambioModel'
import WebmotorsCambioModel from 'src/model/veiculo/WebmotorsCambioModel'
import UsadosbrCambioModel from 'src/model/veiculo/UsadosbrCambioModel'
import OlxCambioModel from 'src/model/veiculo/OlxCambioModel'
import MobiautoCambioModel from 'src/model/veiculo/MobiautoCambioModel'

export default {
  name: 'CambioForm',
  components: { DpForm },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      codigos_icarros: [],
      codigos_webmotors: [],
      codigos_usadosbr: [],
      codigos_olx: [],
      codigos_mobiauto: [],
      modelPadrao: new CambioModel(),
      modelIcarrosCambio: new IcarrosCambioModel(),
      modelWebmotorsCambio: new WebmotorsCambioModel(),
      modelUsadosbrCambio: new UsadosbrCambioModel(),
      modelOlxCambio: new OlxCambioModel(),
      modelMobiautoCambio: new MobiautoCambioModel()
    }
  },
  mounted () {
    this.modelIcarrosCambio.getListagem().then((response) => {
      this.codigos_icarros = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelWebmotorsCambio.getListagem().then((response) => {
      this.codigos_webmotors = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelUsadosbrCambio.getListagem().then((response) => {
      this.codigos_usadosbr = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelOlxCambio.getListagem().then((response) => {
      this.codigos_olx = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelMobiautoCambio.getListagem().then((response) => {
      this.codigos_mobiauto = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
  },
  methods: {

  }
}
